import React, { Fragment, useState } from "react"

import SectionTitle from "../SectionTitle"
import {
  Container,
  SuccessMessage,
  ErrorMessage,
  LoadingIndicator,
} from "../UI"
import { useContactPageData } from "../../system/DatoCms/ContactPageData"
import { sendContactFormData } from "../../functions"

const ContactPage = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
  })
  const [spinner, setSpinner] = useState(false)
  const [showForm, setShowForm] = useState(true)
  const [error, showError] = useState(false)
  const { title } = useContactPageData()

  const changeHandler = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const submitForm = async e => {
    e.preventDefault()
    setSpinner(true)    
    sendContactFormData(data).then(res => {
      setSpinner(false)
      if (res.status === 200) {
        return setShowForm(false)
      }
      showError(true)
    })
  }

  return (
    <section className="min-h-[70vh] bg-white pt-20 pb-52 font-spartan">
      <Container>
        <SectionTitle>Contact us</SectionTitle>
        <p className="mx-auto mb-2 max-w-[800px] text-center">{title}</p>
        {showForm ? (
          <Fragment>
            <form
              name="Contact Form"
              method="POST"
              data-netlify="true"
              action=""
              onSubmit={submitForm}
            >
              <input type="hidden" name="form-name" value="Contact Form" />
              <label htmlFor="name" className="mb-2 block font-semibold">
                Name
              </label>
              <input
                type="text"
                name="name"
                className="mb-3 block w-full border-[1px] border-gray-300 px-2 py-2"
                value={data.name}
                onChange={changeHandler}
              />
              <label htmlFor="email" className="mb-2 block font-semibold">
                Email address
              </label>
              <input
                type="email"
                name="email"
                className="mb-3 block w-full border-[1px] border-gray-300 px-2 py-2"
                value={data.email}
                onChange={changeHandler}
                required
              />
              <label htmlFor="message" className="mb-2 block font-semibold">
                Your message
              </label>
              <textarea
                type="text"
                name="message"
                className="mb-4 block min-h-[150px] w-full border-[1px] border-gray-300 px-2 py-2"
                maxLength={5000}
                value={data.message}
                onChange={changeHandler}
              />
              <div className="relative">
                <input
                  type="submit"
                  className="min-w-[200px] cursor-pointer rounded-header-cta rounded-tl-3xl bg-header-cta px-6 py-4 font-spartan text-base font-bold text-white shadow-header-cta"
                />
                {spinner && (
                  <span className="absolute top-1/2 left-5 -translate-y-1/2">
                    <LoadingIndicator />
                  </span>
                )}
              </div>
            </form>
            {error && (
              <ErrorMessage message="Somethin Went Wrong, Please Try Again" />
            )}
          </Fragment>
        ) : (
          <div className="text-center">
            <SuccessMessage message="Thank you! Your submission has been received!" />
          </div>
        )}
      </Container>
    </section>
  )
}

export default ContactPage
