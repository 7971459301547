import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/SEO"
import ContactPage from "../components/ContactPage"
import { Main } from "../components/UI"

const IndexPage = () => {
  const canonicalUrl = `https://thetoucan.app/contact/`

  return (
    <Layout>
      <Seo title="Contact - Toucan" canonicalUrl={canonicalUrl} />
      <Main className="overflow-hidden">
        <ContactPage />
      </Main>
    </Layout>
  )
}

export default IndexPage
