import axios from "axios"

const charityEndpoint =
  "https://us-central1-toucan-donation-app-prod.cloudfunctions.net/chartiesForm"
const contactEndPoint =
  "https://us-central1-toucan-donation-app-prod.cloudfunctions.net/contactUs"

export async function joinWaitlistHandle(email) {
  const promise = new Promise((resolve, reject) => {
    window.prefinery("addUser", email, function (user) {
      if (user) {
        resolve(user)
      }
      reject({ message: "Some Internal Error" })
    })
  })
  return promise
}

export async function sendCharityFormData(data) {
  const requestMade = await axios({
    url: charityEndpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  })
  return requestMade
}

export async function sendContactFormData({ name, email, message }) {
  var formdata = new FormData()
  formdata.append("name", name)
  formdata.append("email", email)
  formdata.append("message", message)  
  const requestMade = await axios.post(contactEndPoint, {"name": name, "email": email, "message": message});
  return requestMade
}
