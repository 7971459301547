import { useStaticQuery, graphql } from "gatsby"

const useContactPageData = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsContactPage {
        nodes {
          title
        }
      }
    }
  `)
  return data.allDatoCmsContactPage.nodes[0]
}

export default useContactPageData
